
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Logout extends Vue {
  mounted () {
    Services.API.Users.Logout().then(() => {
      this.done()
    }).catch(() => {
      this.done()
    })
  }

  done () {
    this.$store.commit('identity/setUser', null)
    this.$store.commit('identity/setBranch', null)

    Services.CredentialManager.DeleteOAuthCredential()

    this.$router.push('/account/login')
  }
}
